import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Button, SvgIcon } from '@ui-elements';
import { IGenericPopupInterface, IPopupContainerState } from './store';

export const GenericPopup = React.memo((props: IGenericPopupInterface) => {
  const {
    isPopupBlockerActive,
    allowUserToClosePopup,
    showCTAButton,
    openInNewTab,
  } = props;
  const [isPopupActive, setPopupActive] = useState(
    isPopupBlockerActive || false
  );

  return isPopupActive === 'True' ? (
    <div className={styles.genericPopupContainer}>
      <div className={styles.genericPopupBlocker} />
      <div className={styles.genericPopupWrapper}>
        <div
          className={styles.genericPopupHeader}
          style={{
            background: `url(${props.image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            borderRadius: '4px 4px 0',
          }}
        >
          {allowUserToClosePopup === 'True' ? (
            <div
              className={styles.closeButton}
              onClick={() => {
                setPopupActive(!isPopupActive);
              }}
            >
              <SvgIcon type="close" color="#dadadbbb" size="30px" />
            </div>
          ) : (
            <div className={styles.closeButtonDisabled} />
          )}
          <span className={styles.disclaimer}>{props.textdisclaimer}</span>
        </div>
        <div className={styles.genericPopupContent}>
          <h2 className={styles.title}>{props.title}</h2>
          <p className={styles.body}>{props.content}</p>
        </div>
        {showCTAButton === 'True' ? (
          <div className={styles.genericPopupFooter}>
            {openInNewTab === 'False' ? (
              <Button
                className={styles.redirectButton}
                href={props.buttonTarget}
              >
                {props.buttonText}
              </Button>
            ) : (
              <Button
                className={styles.redirectButton}
                target="_blank"
                href={props.buttonTarget}
              >
                {props.buttonText}
              </Button>
            )}
          </div>
        ) : (
          <div className={styles.genericPopupFooter} />
        )}
      </div>
    </div>
  ) : null;
});

export default GenericPopup;
